html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: black;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-size: 48px;
  line-height: 1.2;
  font-family: sans-serif;
  margin: 0;
}

@media (max-width: 800px) {
  body {
    font-size: 36px;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 24px;
  }
}
